<template>
  <v-app>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card class="m-2" id="signin-card">
          <validation-observer ref="form">
            <form @submit.prevent="submit">
              <v-toolbar dark color="primary" class="elevation-0">
                <v-toolbar-title>Sign In</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="reload()" text>
                  <v-icon size="18" class="mr-2">{{ mdiReload }}</v-icon>
                  Reload</v-btn
                >
              </v-toolbar>
              <v-card-text
                :style="{
                  height: $vuetify.breakpoint.xsOnly
                    ? `${contentHeight}px`
                    : 'auto',
                  'overflow-y': 'auto',
                }"
              >
                <v-row class="pb-0">
                  <v-col cols="12" class="d-flex justify-center">
                    <img src="@/assets/UtiliSync_Admin_Logo.svg" id="logo" />
                  </v-col>

                  <v-col cols="12" class="py-0 my-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Username"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.username"
                        :prepend-icon="mdiAccount"
                        label="Username *"
                        hide-details="auto"
                        :error-messages="errors"
                        :success="valid"
                        color="primary"
                        name="username"
                        :disabled="loggingIn"
                        id="username"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="pt-2">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Password"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.password"
                        :prepend-icon="mdiLock"
                        label="Password *"
                        type="password"
                        hide-details="auto"
                        :error-messages="errors"
                        :success="valid"
                        color="primary"
                        name="password"
                        :disabled="loggingIn"
                        id="password"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row class="py-0 my-1">
                  <v-col cols="12">
                    <v-btn
                      type="submit"
                      elevation="0"
                      raised
                      width="100%"
                      color="primary"
                      depressed
                      :disabled="loggingIn"
                      dark
                      id="sign-in"
                    >
                      Sign In
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="pb-0 my-0">
                  <v-col cols="12" class="d-flex justify-end py-0 my-0">
                    <v-btn
                      text
                      color="primary"
                      class="pa-0 ma-0"
                      @click="showForgotPasswordDialog = true"
                      id="forgot-password"
                    >
                      Forgot Password?
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="strike">
                  <span>OR</span>
                </div>

                <v-row class="py-0 my-0">
                  <v-col cols="6">
                    <v-btn
                      type="button"
                      elevation="0"
                      raised
                      width="100%"
                      color="primary"
                      @click="openArcGisSignIn"
                      outlined
                      id="arcgis-online"
                    >
                      <div>ArcGIS Online</div>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      type="button"
                      elevation="0"
                      raised
                      width="100%"
                      color="primary"
                      @click="showPortalUrlDialog = true"
                      outlined
                      id="arcgis-enterprise"
                    >
                      <div>ArcGIS Enterprise</div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-dialog v-model="showLoginFailedDialog" width="500">
        <v-card class="m-2">
          <v-card-text>
            <div class="pt-5">
              {{ errorMessage }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              raised
              text
              color="primary"
              @click="showLoginFailedDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ArcGisEnterpriseLoginDialog
        v-if="showPortalUrlDialog"
        :showPortalUrlDialog="showPortalUrlDialog"
        @portal-url-dialog-close="showPortalUrlDialog = false"
        @portal-login-success="onPortalLoginSuccess"
        @show-invalid-arcgis-user-dialog="
          showArcGisLoginFailureDialog = true;
          error = $event;
        "
      />

      <ForgotPasswordDialog
        :showForgotPasswordDialog="showForgotPasswordDialog"
        @forgot-password-dialog-close="showForgotPasswordDialog = false"
        @password-reset-email-sent="onPasswordResetEmailSent"
      />

      <CreateNewPasswordDialog
        :showCreateNewPasswordDialog="showCreateNewPasswordDialog"
        @reset-password-dialog-close="showCreateNewPasswordDialog = false"
        @password-reset="onPasswordReset"
      />

      <ArcGisLoginFailureDialog
        v-if="showArcGisLoginFailureDialog"
        :show-arc-gis-login-failure-dialog="showArcGisLoginFailureDialog"
        :error="error"
        @close="showArcGisLoginFailureDialog = false"
      />

      <v-snackbar
        v-model="showPasswordResetEmailSnackbar"
        class="link-snackbar"
      >
        <section class="d-flex align-center justify-space-between">
          <div style="white-space: nowrap">
            A link to reset your password has been sent to the email address
            associated with your account.
          </div>
          <v-btn
            class="pa-0 ma-0"
            text
            @click="showPasswordResetEmailSnackbar = false"
          >
            Close
          </v-btn>
        </section>
      </v-snackbar>

      <v-snackbar v-model="showPasswordResetSnackbar">
        <section class="d-flex justify-space-between align-center">
          <div>Your password has now been reset.</div>
          <v-btn
            class="pa-0 ma-0"
            text
            @click="showPasswordResetSnackbar = false"
          >
            Close
          </v-btn>
        </section>
      </v-snackbar>
    </v-row>
  </v-app>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import { debounce } from "lodash";
import usetifulMixin from "@/mixins/usetifulMixin";
import ForgotPasswordDialog from "@/components/login/ForgotPasswordDialog";
import CreateNewPasswordDialog from "@/components/login/CreateNewPasswordDialog";
import ArcGisEnterpriseLoginDialog from "@/components/app/ArcGisEnterpriseLoginDialog";
import { mdiAccount, mdiLock, mdiClose, mdiReload } from "@mdi/js";
import getPopUpDimensions from "@/mixins/getPopUpDimensions";
import ArcGisLoginFailureDialog from "@/components/app/ArcGisLoginFailureDialog";

const APIURL = process.env.VUE_APP_API_URL;
const REDIRECT_URL = process.env.VUE_APP_ESRI_REDIRECT_URL;

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
    ForgotPasswordDialog,
    CreateNewPasswordDialog,
    ArcGisEnterpriseLoginDialog,
    ArcGisLoginFailureDialog,
  },
  mixins: [usetifulMixin],
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      dialog: true,
      loginWindow: undefined,
      portalLoginWindow: undefined,
      portalUrl: "https://",
      showPortalUrlDialog: false,
      showInvalidArcGisUserDialog: false,
      portalSignInClientId: "",
      loggingIn: false,
      showLoginFailedDialog: false,
      showForgotPasswordDialog: false,
      showPasswordResetEmailSnackbar: false,
      showCreateNewPasswordDialog: false,
      showPasswordResetSnackbar: false,
      errorMessage: undefined,
      mdiAccount,
      mdiLock,
      mdiClose,
      mdiReload,
      showArcGisLoginFailureDialog: false,
      error: "",
    };
  },
  mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    const { token } = this.$route.query;
    if (token) {
      this.showCreateNewPasswordDialog = true;
    }
  },
  beforeMount() {
    window.addEventListener("message", this.onMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    reload() {
      location.reload();
    },
    async onMessage({ data }) {
      this.portalLoginWindow?.close();
      this.loginWindow?.close();

      const { access_token: accessToken, redirectType } = data;
      if (!accessToken || redirectType !== "EsriRedirect") {
        return;
      }

      if (localStorage.getItem("message-received")) {
        return;
      }
      localStorage.setItem("message-received", true);
      localStorage.setItem("esri_token", accessToken);
      try {
        const resData = await axios.post(`${APIURL}/auth/esri_login`, {
          access_token: accessToken,
          portal_url: this.loginWindow
            ? "https://www.arcgis.com"
            : localStorage.getItem("portal-url"),
        });
        const {
          data: {
            results: { refreshToken, esriUsername },
          },
        } = resData;
        localStorage.setItem("refresh_token", refreshToken);
        localStorage.setItem("esri_user", esriUsername);
        if (localStorage.getItem("token")) {
          return;
        }
        const refreshTokenData = await axios.post(
          `${APIURL}/auth/refresh_token`,
          {
            refresh_token: localStorage.getItem("refresh_token"),
          }
        );
        const {
          data: {
            results: { token, is_gis_admin: isGisAdmin },
          },
        } = refreshTokenData;
        localStorage.setItem(
          "auth",
          JSON.stringify(refreshTokenData.data.results)
        );
        localStorage.setItem("token", token);
        if (!isGisAdmin) {
          return alert("You must be a GIS admin to log in");
        } else {
          const path = this.$route.query.redirectUrl || "/";
          this.$router.push(path);
        }
      } catch (error) {
        localStorage.removeItem("message-received");
        this.error = error?.response?.data?.error;
        this.showArcGisLoginFailureDialog = true;
      }
    },
    onPortalLoginSuccess() {
      this.showPortalUrlDialog = false;
      const path = this.$route.query.redirectUrl;
      if (path) {
        this.$router.push(path);
      } else {
        this.$router.push("/");
      }
    },
    openArcGisSignIn() {
      localStorage.setItem("portal-url", "https://www.arcgis.com");
      this.portalLoginWindow = undefined;
      this.loginWindow = window.open(
        `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=h3JBZPGpA1fkpkRz&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`,
        "login",
        "width=500,height=500"
      );
      this.loginWindow.focus();
    },
    async getPortalClientId() {
      this.portalSignInClientId = "";
      const getPortalClientId = debounce(async () => {
        await this.$nextTick();
        const success = await this.$refs.portalUrlForm.validate();
        if (!success) {
          return;
        }
        localStorage.setItem("portal-url", this.portalUrl);
        const {
          data: {
            results: { client_id: clientId },
          },
        } = await axios.get(`${APIURL}/esri/get_client_id`, {
          params: {
            portal_url: encodeURI(this.portalUrl),
          },
        });
        this.portalSignInClientId = clientId;
      }, 500);
      getPortalClientId();
    },
    async openPortalSignIn() {
      this.loginWindow = undefined;
      const portalSignInUrl = `${localStorage.getItem(
        "portal-url"
      )}/sharing/oauth2/authorize?client_id=${
        this.portalSignInClientId
      }&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`;
      const { popupWinWidth, popupWinHeight, left, top } = getPopUpDimensions();
      this.portalLoginWindow = window.open(
        portalSignInUrl,
        "login",
        `menubar=0,width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
      );
      this.portalLoginWindow.focus();
      this.showPortalUrlDialog = false;
      this.portalSignInClientId = "";
    },
    onPasswordReset() {
      this.showCreateNewPasswordDialog = false;
      this.showPasswordResetSnackbar = true;
    },
    onPasswordResetEmailSent() {
      this.showForgotPasswordDialog = false;
      this.showPasswordResetEmailSnackbar = true;
    },
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      this.loggingIn = true;
      try {
        const data = await axios.post(`${APIURL}/auth/get_token`, this.user);
        const {
          data: {
            results: {
              refresh_token: refreshToken,
              token,
              is_gis_admin: isGisAdmin,
            },
          },
        } = data;
        localStorage.setItem("auth", JSON.stringify(data.data.results));
        localStorage.setItem("refresh_token", refreshToken);
        localStorage.setItem("token", token);
        if (!isGisAdmin) {
          return alert("You must be a GIS admin to log in");
        } else {
          const path = this.$route.query.redirectUrl || "/";
          this.$router.push(path);
        }
      } catch (error) {
        this.errorMessage = error?.response?.data?.error;
        this.showLoginFailedDialog = true;
      } finally {
        this.loggingIn = false;
      }
    },
  },
  watch: {
    "$route.query.username": {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$route.query.username) {
          this.user.username = decodeURIComponent(this.$route.query.username);
        }
      },
    },
    showPortalUrlDialog(val) {
      if (val) {
        window.removeEventListener("message", this.onMessage);
      } else {
        window.addEventListener("message", this.onMessage);
      }
    },
  },
};
</script>

<style>
.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: black;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.gap {
  gap: 5px;
}
</style>

<style scoped>
#logo {
  width: 70%;
}

.v-btn div {
  white-space: normal;
  word-wrap: break-word;
  width: 100px;
}

.link-snackbar >>> .v-snack__wrapper {
  max-width: none;
}
</style>
